// React
import React, { ComponentType, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

// Internal
import { PermissionTypes } from '@/api/__types/roles.types';
import usePermission from '../../hooks/usePermission';
import type { TooltipProps } from '../AntdCustomized/Tooltip';

// Antd
import { Spin, Tooltip } from 'antd';

// Styles
import styles from './withPermission.module.scss';

export interface PermissionProps {
  permission?: PermissionTypes;
  disabledWithTooltip?: React.ReactNode; // use this to apply the same disabled state UI as when the component has the wrong permission
  tooltipProps?: Omit<TooltipProps, 'title'>;
}

export const MISSING_PERMISSIONS_TOOLTIP_TEXT = 'Ask an admin for access to perform this action';

const withPermission = <P extends object>(
  WrappedComponent: ComponentType<P>,
  {
    permissionType,
  }: {
    permissionType: 'component' | 'route' | 'ag-cell';
  } = { permissionType: 'route' },
): React.ForwardRefExoticComponent<
  React.PropsWithoutRef<P> & React.RefAttributes<any> & PermissionProps
> => {
  // @ts-ignore - some convoluted typing issue
  const WithPermission: React.FC<P & PermissionProps> = React.forwardRef((props, ref) => {
    const { permission, disabledWithTooltip, tooltipProps, ...restProps } = props;

    const navigate = useNavigate();
    const { hasPermission } = usePermission(permission);

    // Memoize the check to prevent unnecessary re-renders
    const isAuthorized = useMemo(() => hasPermission, [hasPermission]);

    if (
      (isAuthorized === false && permission) ||
      (permissionType !== 'route' && disabledWithTooltip)
    ) {
      if (permissionType === 'route') {
        navigate('/404', { replace: true });
      }

      const tooltipTitle =
        isAuthorized === false ? MISSING_PERMISSIONS_TOOLTIP_TEXT : disabledWithTooltip;

      if (permissionType === 'ag-cell') {
        return (
          <Tooltip {...tooltipProps} title={tooltipTitle}>
            {/* NOTE: <span> container is needed here to guaruntee that the tooltip is rendered */}
            <span style={{ width: '100%', cursor: 'not-allowed' }}>
              <WrappedComponent {...(restProps as P)} disabled />
            </span>
          </Tooltip>
        );
      }
      return (
        <Tooltip {...tooltipProps} title={tooltipTitle}>
          {/* NOTE: <span> container is needed here to guaruntee that the tooltip is rendered */}
          <span>
            <WrappedComponent {...(restProps as P)} disabled />
          </span>
        </Tooltip>
      );
    }

    if (isAuthorized === undefined) {
      if (permissionType === 'route') {
        return <Spin className={styles.loading} />;
      } else {
        return null;
      }
    }

    return <WrappedComponent {...(restProps as P)} ref={ref} />;
  });

  // @ts-ignore - some convoluted typing issue
  return WithPermission;
};

export default withPermission;

// Internal
import { getCurrentDateInUTC, offsetDateToUTC } from './date-time';
import { TokenAssetDto } from '@/types/assets';

// Misc
import BigNumber from 'bignumber.js';
import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInMonths,
  differenceInSeconds,
  differenceInYears,
  format,
  fromUnixTime,
} from 'date-fns';
import { truncate as truncateLodash } from 'lodash-es';
import { ANTD_CLASSES } from '@/components/AntdCustomized/antd-classes';

interface FormatDollarValueArgs {
  showBracketsIfNecessary?: boolean;
  showBrackets?: boolean;
  showDashInsteadOfZero?: boolean;
  showDollarSign?: boolean;
  showCentsWhenZero?: boolean;
  decimal?: number;
}

export const EN_DASH = '–'; // shorter than em-dash
export const INTERPUNCT = '·';
export const BREADCRUMB_SEPARATOR = ' > ';

export const formatNumberToWords = (number: number) => {
  const _mainNumber = BigNumber(number);

  const _DECIMAL_PLACE = 2;
  const _RANGES = [
    { minValue: 1e12, unit: 'T' },
    { minValue: 1e9, unit: 'B' },
    { minValue: 1e6, unit: 'M' },
    { minValue: 1e3, unit: 'K' },
    { minValue: 1, unit: '' },
  ];
  let matchingRange = _RANGES.find((d) => _mainNumber.isGreaterThanOrEqualTo(d.minValue));

  if (matchingRange) {
    let _finalNumber = _mainNumber.dividedBy(matchingRange.minValue);

    // After rounding, if we've hit another range (e.g. went from 999.999k to 1M), update the matching range and the final number
    if (
      BigNumber(_finalNumber.toFixed(_DECIMAL_PLACE)).toNumber() >= 1000 &&
      matchingRange.unit !== _RANGES[0].unit
    ) {
      _finalNumber = _finalNumber.dividedBy(1000);
      matchingRange = _RANGES[_RANGES.indexOf(matchingRange) - 1];
    }

    // No decimals shown if number is between these ranges
    const _isBetween10kAnd1milOrLargerThan10Mil =
      (_mainNumber.isGreaterThanOrEqualTo(10000) && _mainNumber.isLessThan(1000000)) ||
      _mainNumber.isGreaterThanOrEqualTo(10000000);

    const _abbreviatedNumber = `${_finalNumber.toFormat(_finalNumber.decimalPlaces() === 0 || _isBetween10kAnd1milOrLargerThan10Mil ? 0 : _DECIMAL_PLACE).replace(/[.,]0$/, '')}`;

    // if there are decimals, only show the first one
    // (we just truncate the decimal to 1 digit, rather than rounding it)
    const _splitByDecimal = _abbreviatedNumber.split('.');
    if (_splitByDecimal.length === 2) {
      return `${_splitByDecimal[0]}.${_splitByDecimal[1][0]}${matchingRange.unit}`;
    }

    return `${_abbreviatedNumber}${matchingRange.unit}`;
  }

  return '0';
};

/** @deprecated: use formatAssetAmount instead, with getStandardUsdFiatAsset */
export const formatDollarValue = (value: any, configs?: FormatDollarValueArgs | undefined) => {
  const showBracketsIfNecessary = configs?.showBracketsIfNecessary;
  const showBrackets = configs?.showBrackets;
  const showDashInsteadOfZero = configs?.showDashInsteadOfZero;
  const showDollarSign = configs?.showDollarSign;
  const showCentsWhenZero = configs?.showCentsWhenZero;
  const decimal = configs?.decimal ?? 2;

  const parsedValue = new BigNumber(value as string);
  const floatValue = parsedValue.toNumber();
  const absValue = Math.abs(floatValue);
  const isNegative = parsedValue.isNegative();
  const isZero = parsedValue.isZero();
  const isLessThanACent = absValue < 0.01;

  if (parsedValue.isNaN() || (parsedValue.isZero() && showDashInsteadOfZero !== false)) {
    return EN_DASH;
  }

  let result =
    !isZero && isLessThanACent
      ? '0.01'
      : parsedValue.abs().toFormat(showCentsWhenZero !== false || !isZero ? decimal : 0);

  if (!isZero && isLessThanACent) {
    result = `<${result}`;
  }

  if (showDollarSign !== false) {
    result = `$${result}`;
  }

  if (
    !parsedValue.isZero() &&
    (showBrackets || (showBracketsIfNecessary !== false && isNegative))
  ) {
    result = `(${result})`;
  }

  return result;
};

export const formatPercentageValue = (percentage: number) => {
  if (isNaN(percentage) || percentage === 0) {
    return { result: null, isNegative: false };
  }

  const absolutePercentage = Math.abs(percentage);
  const isNegative = percentage < 0;

  const result =
    absolutePercentage > 1
      ? Math.floor(absolutePercentage).toLocaleString()
      : absolutePercentage.toLocaleString(undefined, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        });

  return { result, isNegative };
};

export const truncate = (str?: string | null) => {
  const START_END_CUTOFF_LENGTH = 5;

  if (str && str.length > START_END_CUTOFF_LENGTH * 2 + 2) {
    return `${str.slice(0, START_END_CUTOFF_LENGTH)}...${str.slice(
      str.length - START_END_CUTOFF_LENGTH,
    )}`;
  } else if (str !== undefined && str !== null) {
    return str;
  } else {
    return '';
  }
};

export const truncateMultiLines = (
  str: string,
  options: { lengthPerLine: number; lines: number },
) => {
  if (str !== null && str !== undefined) {
    let newStr = '';
    let numberOfLines = 0;
    while (str && numberOfLines < options.lines) {
      if (numberOfLines > 0) newStr += '\n';
      if (numberOfLines === options.lines - 1) {
        newStr += truncateLodash(str.trim(), {
          length: options.lengthPerLine,
        });
      } else {
        newStr += str.trim().slice(0, options.lengthPerLine);
      }
      numberOfLines += 1;
      str = str.slice(options.lengthPerLine);
    }

    return newStr;
  }

  return '';
};

export type FormatNumberConfig = {
  truncateDecimals?: boolean;
  showDashInsteadOfZero?: boolean;
};

/*
 * General purpose number formatter for most situations:
 * NOTE: use `formatDollarValue` instead of this when handling with dollar values
 * adds thousands-separators
 * truncates the decimal part up after 6 decimal places (shows '…' afterwards)
 * eg. 123456.123456789 -> 123,456.123456...
 */
export const formatNumber = (
  number?: number | string | BigNumber | null,
  config?: FormatNumberConfig,
) => {
  const { truncateDecimals: truncateDecimalsEnabled = true, showDashInsteadOfZero } = config || {};

  if (number === undefined || number === null || (number === 0 && showDashInsteadOfZero)) {
    return EN_DASH;
  }

  const [integerPart, decimalPart] = new BigNumber(number).toFixed().split('.');
  const truncatedValue =
    truncateDecimalsEnabled && decimalPart !== undefined
      ? truncateLodash(decimalPart, { length: 6, omission: '' })
      : undefined;

  const requiresTruncation =
    decimalPart && truncatedValue !== undefined && truncatedValue !== decimalPart;

  const formattedIntegerPart = new BigNumber(integerPart).toFormat(); // Adds thousands-separator (ie. commas)
  const formattedDecimalPart = requiresTruncation
    ? `.${truncatedValue}`
    : decimalPart !== undefined
      ? `.${decimalPart}`
      : '';
  const truncationIndicator = requiresTruncation ? '…' : '';

  return `${formattedIntegerPart}${formattedDecimalPart}${truncationIndicator}`;
};

export const containsNonWhitespace = (name?: string | null) => {
  // `true` if contains one character of non-whitespace
  return /\S/.test(name || '');
};

export const getFirstNonWhitespaceCharacter = (inputString?: string | null) => {
  if (inputString === undefined || inputString === null) {
    return '?';
  }

  const match = inputString.match(/\S/);
  return match ? match[0] : '?';
};

/**
 * Returns the initial(s) for an avatar based on the input string.
 * If the input string is undefined, null, or only whitespace, returns '?'.
 * @param inputString - The input string to generate initials from.
 * @returns The initials for the avatar.
 */
export const getInitialsForAvatar = (inputString?: string | null) => {
  if (!inputString || !inputString.trim()) {
    return '?';
  }

  const words = inputString.trim().split(/\s+/);

  const firstWord = words[0] || '';
  const secondWord = words[1] || '';

  return (firstWord.charAt(0) + secondWord.charAt(0)).toUpperCase();
};

type GetRelativeTimeToNowConfig = {
  useLimitedSpace?: boolean;
  addSuffix?: boolean;
  minimumDeltaThresholdSeconds?: number; // minimum difference in seconds between two dates to consider them as being different
  maxUnitOfMeasure?: 'minutes' | 'hours' | 'days';
};

/**
 * Get the relative distance between a given date and now in words
 * @param date -  can be a Date or number in unix time
 * @param config
 * @returns relative distance between the given date and now in words
 */
export const getRelativeTimeToNow = (
  date?: Date | string | number | null,
  config?: GetRelativeTimeToNowConfig,
) => {
  if (date === undefined || date === null) {
    return EN_DASH;
  }

  const {
    useLimitedSpace = true,
    addSuffix = true,
    minimumDeltaThresholdSeconds = 60,
    maxUnitOfMeasure,
  } = config ?? {};

  if (typeof date === 'string') {
    date = new Date(date);
  }

  // Ensure that the date is in UTC
  if (date instanceof Date) {
    date = offsetDateToUTC(date);
  } else {
    date = fromUnixTime(date);
  }

  // `nowUtc` Date should be in UTC for consistency
  const nowUtc = getCurrentDateInUTC();

  // < 1 minute
  if (Math.abs(differenceInSeconds(date, nowUtc)) <= minimumDeltaThresholdSeconds) {
    return !useLimitedSpace ? 'just now' : 'Just now';
  }

  // < 1 minute
  else if (Math.abs(differenceInSeconds(date, nowUtc)) <= 120) {
    return `${!useLimitedSpace ? 'a minute' : '1m'}${addSuffix ? ' ago' : ''}`;
  }

  // < 1 hour
  else if (Math.abs(differenceInMinutes(date, nowUtc)) < 60 || maxUnitOfMeasure === 'minutes') {
    const delta = Math.abs(differenceInMinutes(date, nowUtc));

    return `${delta}${!useLimitedSpace ? ` minute${delta !== 1 ? 's' : ''}` : 'm'}${
      addSuffix ? ' ago' : ''
    }`;
  }

  // < 24 hours
  else if (Math.abs(differenceInHours(date, nowUtc)) < 24 || maxUnitOfMeasure === 'hours') {
    const delta = Math.abs(differenceInHours(date, nowUtc));

    return `${delta}${!useLimitedSpace ? ` hour${delta !== 1 ? 's' : ''}` : 'h'}${
      addSuffix ? ' ago' : ''
    }`;
  }

  // > 2 days && < 7 days
  else if (
    (Math.abs(differenceInDays(date, nowUtc)) >= 1 &&
      Math.abs(differenceInDays(date, nowUtc)) < 7) ||
    maxUnitOfMeasure === 'days'
  ) {
    const delta = Math.abs(differenceInDays(date, nowUtc));

    return `${delta}${!useLimitedSpace ? ` day${delta !== 1 ? 's' : ''}` : 'd'}${
      addSuffix ? ' ago' : ''
    }`;
  }

  // exactly 7 days
  else if (Math.abs(differenceInDays(date, nowUtc)) === 7) {
    return `${!useLimitedSpace ? '1 week' : '1w'}${addSuffix ? ' ago' : ''}`;
  }

  // > 7 days
  else if (Math.abs(differenceInDays(date, nowUtc)) < 31) {
    return !useLimitedSpace ? format(date, 'MMMM d') : format(date, 'MMM d');
  }

  // > 31 days
  else if (Math.abs(differenceInMonths(date, nowUtc)) < 12) {
    const delta = Math.abs(differenceInMonths(date, nowUtc));

    return `${delta}${!useLimitedSpace ? ` month${delta !== 1 ? 's' : ''}` : 'mo'}${
      addSuffix ? ' ago' : ''
    }`;
  }

  // > 1 year
  const delta = Math.abs(differenceInYears(date, nowUtc));
  return `${delta}${!useLimitedSpace ? ` year${delta !== 1 ? 's' : ''}` : 'y'}${
    addSuffix ? ' ago' : ''
  }`;
};

/**
 * Formats the count of an entity with the appropriate pluralization.
 * e.g. `formatEntityCount({number: 100000, entityName: 'Thing'})` -> returns '100,000 Things'
 *
 * @param number - The count of the entity.
 * @param entityName - The name of the entity.
 * @param customPlural - (Optional) The custom plural form of the entity name.
 * @param formattingLogic - (Optional) A string indicating the type of formatting to apply for certain values (e.g. 0, undefined, null)

 * @returns The formatted count with the appropriate pluralization.
 */
export const formatEntityCount = ({
  number,
  entityName,
  customPlural,
  formattingLogic,
}: {
  number: number | string | BigNumber | undefined | null;
  entityName: string;

  // Optional
  customPlural?: string;
  formattingLogic?: 'hide-if-falsy' | 'hide-if-zero';
}): string | null => {
  if (formattingLogic === 'hide-if-falsy' && BigNumber(number ?? 0).isZero()) {
    return null;
  }

  if (
    formattingLogic === 'hide-if-zero' &&
    number !== undefined &&
    number !== null &&
    BigNumber(number).isZero()
  ) {
    return null;
  }

  const pluralName = customPlural || `${entityName}s`;
  if (number === null || number === undefined) {
    return `${EN_DASH} ${pluralName}`;
  }

  let formattedNumber = BigNumber(number).toFormat();

  return `${formattedNumber} ${number !== 1 ? pluralName : entityName}`;
};

export const getDecimalPlacesCount = (stringNumber: string) => {
  const match = stringNumber.match(/\.(\d+)/);
  return match ? match[1].length : 0;
};

export const getTokenAssetDetails = (asset: TokenAssetDto | undefined) => {
  const tokenNameRaw = asset?.name?.replace('placeholder', '') || asset?.ticker || 'Unknown';
  const isTokenNameUnknown =
    asset?.name?.toLowerCase() === 'placeholder' ||
    tokenNameRaw.toLowerCase() === 'token' ||
    tokenNameRaw.toLowerCase() === 'unknown' ||
    tokenNameRaw.toLowerCase() === 'unknown token';
  const tokenTicker = asset?.ticker || 'Unknown';
  const tokenName = isTokenNameUnknown ? tokenTicker : tokenNameRaw;
  return { isTokenNameUnknown, tokenTicker, tokenName };
};

/**
 * Get the next item in an array.
 *
 * @template T - The type of the array elements.
 * @param {Object} params - The parameters object.
 * @param {T[]} params.array - The array to search in.
 * @param {T} params.currentItem - The current item in the array.
 * @returns {T | null} - The next item in the array, or `null` if it's the last item.
 */
export const getNextItem = <T,>({
  array,
  currentItem,
}: {
  array: T[];
  currentItem: T;
}): T | null => {
  const currentIndex = array.indexOf(currentItem);
  const lastIndex = array.length - 1;

  // If it's the last item, return `null`
  if (currentIndex === lastIndex) {
    return null;
  }

  return array[currentIndex + 1];
};

/*
 * Helper function that checks if ANY modal or Drawer is open
 */
export function isModalOrDrawerOpen() {
  const elements = document.body.querySelectorAll(
    `.${ANTD_CLASSES.modal.innerContent}, .${ANTD_CLASSES.drawer.contentWrapper}`,
  );

  return elements.length > 0;
}

export function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}

/**
 * Formats a list of entity names.
 *
 * @param names - The array of entity names to format.
 * @returns The formatted string.
 * e.g. "wallet A, wallet, B, wallet C, and 32 others"
 */
export const formatListOfEntityNames = (names: string[]): string => {
  if (names.length === 0) return EN_DASH;

  if (names.length === 1) {
    return names[0];
  } else if (names.length === 2) {
    return names.join(' and ');
  } else if (names.length === 3 || names.length === 4) {
    return names.slice(0, -1).join(', ') + ', and ' + names.slice(-1);
  } else {
    const firstThree = names.slice(0, 3).join(', ');
    const remainingCount = names.length - 3;
    return `${firstThree}, and ${formatEntityCount({ number: remainingCount, entityName: 'other' })}`;
  }
};

export function isNonEmptyArray<T>(arr: T[]): arr is [T, ...T[]] {
  return Array.isArray(arr) && arr.length > 0;
}

export const isInternalEmail = (email: string | undefined | null): boolean => {
  return email?.endsWith('@integral.xyz') ?? false;
};

import {
  ApiResponse,
  getRequest,
  patchRequest,
  postMultipartFormRequest,
  postRequest,
  deleteRequest,
} from './request';
import {
  CustomTxnsImportDto,
  CustomTxnsImportHistoryDto,
  ExportCSVDto,
  GainLossStatus,
  GetTransactionsDto,
  GetTxnCountsDto,
  InteractedWithOption,
  SearchTxnsByHashDto,
  TagsDto,
  Transaction,
  TransactionCounts,
  TransactionDetailDto,
  TransactionImportDto,
  UpdateTagDto,
  UpdateTransactionDto,
  UpdateTransactionsInBulkDto,
} from './__types/transactions.types';

// react-query
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';

// Export types
export * from './__types/transactions.types';

// Query keys
export const transactionsQueryKeys = {
  all: ['transactions'] as const,

  lists: () => [...transactionsQueryKeys.all, 'list'] as const,
  listByQuery: (dto: GetTransactionsDto) => [...transactionsQueryKeys.lists(), dto] as const,
  listByHash: (dto: SearchTxnsByHashDto) => [...transactionsQueryKeys.lists(), dto] as const,

  details: () => [...transactionsQueryKeys.all, 'details'] as const,
  detail: (dto: TransactionDetailDto) => [...transactionsQueryKeys.details(), dto] as const,

  filtersLists: () => [...transactionsQueryKeys.lists(), 'filter'] as const,
  filtersList: (searchQuery: string) =>
    [...transactionsQueryKeys.filtersLists(), searchQuery] as const,

  transactionCounts: () => [...transactionsQueryKeys.lists(), 'count'] as const,
  transactionCount: (dto?: GetTxnCountsDto) =>
    [...transactionsQueryKeys.transactionCounts(), dto] as const,
};

export const getTransactions = (dto: GetTransactionsDto) =>
  getRequest<ApiResponse<Transaction[]>>('/transactions', dto);
export const useGetTransactions = (dto: GetTransactionsDto) => {
  return useQuery<Transaction[], Error>({
    queryKey: transactionsQueryKeys.listByQuery(dto),
    queryFn: async () => {
      const { body } = await getTransactions(dto);
      return body.data;
    },
  });
};

export const searchTxnsByHash = (dto: SearchTxnsByHashDto) =>
  getRequest<ApiResponse<Transaction[]>>('/transactions', { txnHashSearch: dto.hash });
export const useSearchTxnsByHash = (dto: SearchTxnsByHashDto) => {
  return useQuery<Transaction[], Error>({
    queryKey: transactionsQueryKeys.listByHash(dto),
    queryFn: async () => {
      if (!dto.hash) {
        return null;
      }

      const { body } = await searchTxnsByHash(dto);
      return body.data;
    },
  });
};

export const useTransactionDetails = (dto: TransactionDetailDto) => {
  return useQuery<Transaction>({
    queryKey: transactionsQueryKeys.detail(dto),
    queryFn: async () => {
      const { body } = await getTransactionDetails(dto);
      return body.data;
    },
  });
};

export const useUpdateTransaction = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (params: { id: string; dto: UpdateTransactionDto }) => {
      const { id, dto } = params;
      const { body } = await updateTransaction(id, dto);
      return body.data;
    },
    onSuccess: (_, variables) => {
      message.success('Successfully updated!');
      queryClient.setQueryData<Transaction>(
        transactionsQueryKeys.detail({ id: variables.id }),
        (prev) => {
          if (!prev) {
            return prev;
          }
          return { ...prev, ...variables.dto };
        },
      );
    },
  });
};
export const getTransactionCounts = (dto?: GetTxnCountsDto) =>
  getRequest<ApiResponse<TransactionCounts>>('/transactions/counts-with-filter', dto);
export const useTransactionCounts = (dto?: GetTxnCountsDto) => {
  return useQuery<TransactionCounts, Error>({
    queryKey: [transactionsQueryKeys.transactionCount(dto)],
    queryFn: async () => {
      const { body } = await getTransactionCounts(dto);
      return body.data;
    },
  });
};

export const getTransactionDetails = (dto: TransactionDetailDto) =>
  getRequest<ApiResponse<Transaction>>(`/transactions/${dto.id}`, {});

export const exportTransaction = (dto: ExportCSVDto) =>
  postRequest<string>('/transactions/export', dto, { Accept: 'application/json,text/csv' });

export const importTransactions = (formData: FormData) => {
  return postMultipartFormRequest('/transactions/import', formData);
};

export const customTxnsValidate = (formData: FormData) => {
  return postMultipartFormRequest('/transaction-imports/validate', formData);
};

export const customTxnsImport = (dto: CustomTxnsImportDto) => {
  return postRequest('/transaction-imports/start', dto);
};

export const getCustomTxnsImportHistory = (dto: CustomTxnsImportHistoryDto) => {
  return getRequest<ApiResponse<TransactionImportDto[]>>('/transaction-imports/', dto);
};
export const deleteCustomTxnsImport = (id: string) => {
  return deleteRequest(`/transaction-imports/${id}`, {});
};

export const downloadCustomTxnImport = (id: string) => {
  return getRequest<string>(
    `/transaction-imports/${id}/download`,
    {},
    { Accept: 'text/csv' },
    { responseType: 'csv' },
  );
};

export const updateTransaction = (transactionId: string, dto: UpdateTransactionDto) =>
  patchRequest<ApiResponse<Transaction>>(`/transactions/${transactionId}`, dto);

export const updateTransactionsInBulk = (
  transactionIDs: string[],
  dto: UpdateTransactionsInBulkDto,
) =>
  patchRequest<ApiResponse<Transaction>>('/transactions', {
    ids: transactionIDs,
    ...dto,
  });

export const updateTransactionsByQuery = (dto: {
  query: GetTransactionsDto;
  update: {
    moveTo: 'Categorized' | 'ForReview' | 'ReadyToSync' | 'Synced' | 'Excluded';
  };
}) => postRequest<ApiResponse<Transaction[]>>('/transactions/update-by-query', dto);

export const stopUpdateTransactionsByQuery = () =>
  postRequest<ApiResponse<Transaction[]>>('/transactions/stop-update-by-query', {});

/**
 * @deprecated The enrich-transactions feature is no longer supported
 */
export const enrichTransactions = (formData: FormData) => {
  return postMultipartFormRequest<ApiResponse<Transaction[]>>('/transactions/enrich', formData);
};

export const getTransactionTags = () =>
  getRequest<ApiResponse<Transaction>>('/transaction-tags', {});

export const createTransactionTags = (tagForm: TagsDto) =>
  postRequest<ApiResponse<Transaction[]>>('/transaction-tags', tagForm);

export const deleteTransactionTag = (tagId: string) =>
  deleteRequest<ApiResponse<string>>(`/transaction-tags/${tagId}`, {});

export const updateTransactionTag = (tagId: string, tagName: UpdateTagDto) =>
  patchRequest<ApiResponse<string>>(`/transaction-tags/${tagId}`, tagName);

export const getInteractedWithSuggestions = (query: string) =>
  getRequest<ApiResponse<InteractedWithOption>>(
    `/transactions/interacted-with-suggestions?search=${query}`,
    null,
  );

export const getGainLossStatus = () =>
  getRequest<ApiResponse<GainLossStatus>>('/status/gain-loss', null);

import { AccountingSyncValidationErrorCode } from './accounting.types';

export enum AccountingIntegrationStatus {
  Connected = 'Connected',
  Errored = 'Errored',
  Expired = 'Expired',
}

export interface AccountingIntegrationConnectionDto {
  id: string;
  createdAt: string;
  credentialRefreshedAt: string;
  companyName: string;
  companyId: string;
  lastError?: string;
  lastErrorAt?: string;
  status: AccountingIntegrationStatus;
  accountingDataSyncedAt?: string;
  type: AccountingIntegration;
}

/**
 * (BE refers to this as `SyncState`)
 * Generally the states should be interpreted as:
 *
 * - cancelled -- Synced {successCount} journal entries
 * - errored -- {failCount} journal entries did not sync
 * - pending/creating -- Preparing journal entries...
 *   - during this step, journal entries are being created in the BE (not synced yet);
 *   - during this step, the `entryCount` is still increasing;
 *   - NOTE: rarely ever gets into the "pending" state
 * syncing -- {successCount} of {entryCount} journal entries synced
 *   - this is when the actual sync to ERP is happening;
 *   - during this step, the `entryCount` is no longer increasing;
 * - finished -- Synced {successCount} journal entries
 */
export enum JournalEntrySyncState {
  pending = 'pending',
  creating = 'creating',
  syncing = 'syncing',
  cancelled = 'cancelled',
  finished = 'finished',
  errored = 'errored',
}

export interface SyncStatusResponseDto {
  id: string;
  totalTxnCount: number; // number of txns included in this sync
  entryCount: number; // number of journal entries that have been processed/created -- TODO: rename this variable to `processedEntryCount`
  successCount: number; // number of journal entries that successfully synced
  failCount: number; // number of journal entries that failed to sync
  unknownCount: number; // number of journal entries that the sync status was unknown
  state: JournalEntrySyncState; // e.g. "syncing"
  cancelled: boolean;
  finishedAt?: string; // date string
  progress: number | null; // progress out of 100 (e.g. 80)
  estimatedCompletion: number | null; // estimated time to completion in seconds (e.g. 80)
  exportOnly: boolean;
}

export enum AccountingIntegration {
  Quickbooks = 'Quickbooks',
  Xero = 'Xero',
}

export interface StartAccountingSyncOptionsDto {
  // Set to `true` if the journal entries will be only exported instead of pushed to an accounting software.
  exportOnly?: boolean;
  // Use if `exportOnly` is `true`. If true, transactions included in the journal entry export will be marked as synced.
  markTxnsSyncedAfterExport?: boolean;
  // Set to `true` if validation warnings should be ignored. Validation errors cannot be ignored.
  // i.e. ignore warnings of type AccountingSyncValidationError['isWarning'] that are `true`
  ignoreWarnings?: boolean;
}

export interface StartAccountingSyncRequestParamsDto {
  page?: number;
  limit?: number;
  filtersQueryString?: string;
}

export interface StartAccountingSyncRequestData {
  dto?: StartAccountingSyncOptionsDto;
  params?: StartAccountingSyncRequestParamsDto;
}

export type RollupNeededData = {
  transactionCount: number;
};

export type DisabledAccountingClassData = {
  accountingClasses: { id: string; name: string }[];
};

export type WalletMappingMissingData = {
  missingAccounts: { id: string; name: string }[];
};

export type AssetMappingMissingData = {
  missingAssets: { type: 'token' | 'collection'; name: string; symbol: string }[];
};

export type BookAccountListData = {
  bookAccounts: { id: string; name?: string; currency?: string }[];
};

export type IncompatibleCurrencyData = {
  integralCurrency: string;
  accountingCurrency: string;
  isMultiCurrencyEnabled: boolean;
};

export type BookCloseDateData = {
  bookCloseDate: string;
};

type AccountingSyncValidationErrorCodeWithData =
  | AccountingSyncValidationErrorCode.RollupNeeded
  | AccountingSyncValidationErrorCode.WalletMappingMissing
  | AccountingSyncValidationErrorCode.AssetMappingMissing
  | AccountingSyncValidationErrorCode.QuickBooksCompanyCurrencyNotUsd
  | AccountingSyncValidationErrorCode.DisabledBookAccounts
  | AccountingSyncValidationErrorCode.BookAccountCurrencyMismatch
  | AccountingSyncValidationErrorCode.InvalidBookAccount
  | AccountingSyncValidationErrorCode.BookClosed
  | AccountingSyncValidationErrorCode.DisabledAccountingClass;

export type AccountingSyncValidationErrorData =
  | RollupNeededData
  | WalletMappingMissingData
  | AssetMappingMissingData
  | IncompatibleCurrencyData
  | BookAccountListData
  | BookCloseDateData
  | DisabledAccountingClassData;

export type AccountingSyncValidationError = {
  isWarning: boolean;
  message: string;
} & (
  | {
      code: Exclude<AccountingSyncValidationErrorCode, AccountingSyncValidationErrorCodeWithData>;
      data?: undefined;
    }
  | {
      code: AccountingSyncValidationErrorCode.RollupNeeded;
      data?: RollupNeededData;
    }
  | {
      code: AccountingSyncValidationErrorCode.WalletMappingMissing;
      data?: WalletMappingMissingData;
    }
  | {
      code: AccountingSyncValidationErrorCode.AssetMappingMissing;
      data?: AssetMappingMissingData;
    }
  | {
      code: AccountingSyncValidationErrorCode.QuickBooksCompanyCurrencyNotUsd;
      data?: IncompatibleCurrencyData;
    }
  | {
      code:
        | AccountingSyncValidationErrorCode.DisabledBookAccounts
        | AccountingSyncValidationErrorCode.BookAccountCurrencyMismatch
        | AccountingSyncValidationErrorCode.InvalidBookAccount;
      data?: BookAccountListData;
    }
  | {
      code: AccountingSyncValidationErrorCode.BookClosed;
      data?: BookCloseDateData;
    }
  | {
      code: AccountingSyncValidationErrorCode.DisabledAccountingClass;
      data?: DisabledAccountingClassData;
    }
);

export interface AccountingSyncConflictResponse {
  statusCode: number;
  code: string; // e.g. "resource_conflict"
  message: string; // e.g. "Validation failed"
  param: AccountingSyncValidationError[];
}
export type SyncErrorsResponseDto = {
  id: string;
  entrySyncErrors?: string[];
  entryErrors?: string[];
};

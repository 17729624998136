import { ChainId } from './useChains';

export const ARROW_ASCII = '→';

export const CHAINS: {
  [key in ChainId]: {
    id: ChainId;
    chainId?: string; // hex encoded string for evm chains
    explorerName: string;
    addressExplorer: (address: string) => string;
    nftExplorer?: (collectionId: string, tokenId: string) => string;
    txnExplorer: (txnHash: string) => string;
    txServiceUrl?: string; // https://docs.safe.global/core-api/transaction-service-supported-networks
  };
} = {
  blast: {
    id: 'blast',
    chainId: '0x' + Number(81457).toString(16),
    explorerName: 'Blastscan',
    addressExplorer: (address: string) => `https://blastscan.io/address/${address}`,
    nftExplorer: (collectionId: string, tokenId: string) =>
      ['https://blast.nftscan.com', collectionId, tokenId].join('/'),
    txnExplorer: (txnHash: string) => `https://blastscan.io/tx/${txnHash}`,
  },
  ethereum: {
    id: 'ethereum',
    chainId: '0x1',
    explorerName: 'Etherscan',
    addressExplorer: (address: string) => 'https://etherscan.io/address/' + address,
    nftExplorer: (collectionId: string, tokenId: string) =>
      'https://etherscan.io/nft/' + collectionId + '/' + tokenId,
    txnExplorer: (txnHash: string) => 'https://etherscan.io/tx/' + txnHash,
    txServiceUrl: 'https://safe-transaction-mainnet.safe.global/',
  },
  cardano: {
    id: 'cardano',
    explorerName: 'Cardanoscan',
    addressExplorer: (address: string) => 'https://cardanoscan.io/address/' + address,
    nftExplorer: (collectionId: string, tokenId: string) =>
      ['https://www.jpg.store/asset', tokenId].join('/'),
    txnExplorer: (txnHash: string) => 'https://cardanoscan.io/transaction/' + txnHash,
  },
  solana: {
    id: 'solana',
    explorerName: 'Solana.fm',
    addressExplorer: (address: string) => 'https://solana.fm/account/' + address,
    nftExplorer: (collectionId: string, tokenId: string) =>
      ['https://solana.nftscan.com', tokenId].join('/'),
    txnExplorer: (txnSignature: string) => 'https://solana.fm/tx/' + txnSignature,
  },
  polygon: {
    id: 'polygon',
    chainId: '0x89',
    explorerName: 'PolygonScan',
    addressExplorer: (address: string) => 'https://polygonscan.com/address/' + address,
    nftExplorer: (collectionId: string, tokenId: string) =>
      'https://polygonscan.com/nft/' + collectionId + '/' + tokenId,
    txnExplorer: (txnHash: string) => 'https://polygonscan.com/tx/' + txnHash,
    txServiceUrl: 'https://safe-transaction-polygon.safe.global/',
  },
  'binance-smart-chain': {
    id: 'binance-smart-chain',
    chainId: '0x38',
    explorerName: 'BscScan',
    addressExplorer: (address: string) => 'https://bscscan.com/address/' + address,
    nftExplorer: (collectionId: string, tokenId: string) =>
      ['https://bnb.nftscan.com', collectionId, tokenId].join('/'),
    txnExplorer: (txnHash: string) => 'https://bscscan.com/tx/' + txnHash,
    txServiceUrl: 'https://safe-transaction-bsc.safe.global/',
  },
  arbitrum: {
    id: 'arbitrum-one',
    chainId: '0xa4b1',
    explorerName: 'ArbiScan',
    addressExplorer: (address: string) => 'https://arbiscan.io/address/' + address,
    nftExplorer: (collectionId: string, tokenId: string) =>
      ['https://arbitrum.nftscan.com', collectionId, tokenId].join('/'),
    txnExplorer: (txnHash: string) => 'https://arbiscan.io/tx/' + txnHash,
    txServiceUrl: 'https://safe-transaction-arbitrum.safe.global/',
  },
  arbitrumNova: {
    id: 'arbitrum-nova',
    chainId: '0xa4ba',
    explorerName: 'ArbiScan Nova',
    addressExplorer: (address: string) => 'https://nova.arbiscan.io/address/' + address,
    txnExplorer: (txnHash: string) => 'https://nova.arbiscan.io/tx/' + txnHash,
  },
  linea: {
    id: 'linea',
    chainId: '0x' + Number(59144).toString(16),
    explorerName: 'LineaScan',
    addressExplorer: (address: string) => `https://lineascan.build/address/${address}`,
    txnExplorer: (txnHash: string) => `https://lineascan.build/tx/${txnHash}`,
    nftExplorer: (collectionId: string, tokenId: string) =>
      ['https://linea.nftscan.com', collectionId, tokenId].join('/'),
  },
  tronscan: {
    id: 'tronscan',
    explorerName: 'TronScan',
    addressExplorer: (address: string) => 'https://tronscan.org/#/address/' + address,
    txnExplorer: (txnHash: string) => 'https://tronscan.org/#/transaction/' + txnHash,
  },
  avalanche: {
    id: 'avalanche',
    chainId: '0xa86a',
    explorerName: 'SnowTrace',
    addressExplorer: (address: string) => 'https://avascan.info/blockchain/c/address/' + address,
    nftExplorer: (collectionId: string, tokenId: string) =>
      ['https://avax.nftscan.com', collectionId, tokenId].join('/'),
    txnExplorer: (txnHash: string) => 'https://avascan.info/blockchain/c/tx/' + txnHash,
    txServiceUrl: 'https://safe-transaction-avalanche.safe.global/',
  },
  mantle: {
    id: 'mantle',
    chainId: '0x' + Number(5000).toString(16),
    explorerName: 'mantlescan',
    nftExplorer: (collectionId: string, tokenId: string) =>
      ['https://mantle.nftscan.com', collectionId, tokenId].join('/'),
    addressExplorer: (address: string) => 'https://mantlescan.xyz/address/' + address,
    txnExplorer: (txnHash: string) => 'https://mantlescan.xyz/tx/' + txnHash,
  },
  mode: {
    id: 'mode',
    chainId: '0x' + Number(34443).toString(16),
    explorerName: 'modescout',
    addressExplorer: (address: string) => 'https://explorer.mode.network/address/' + address,
    txnExplorer: (txnHash: string) => 'https://explorer.mode.network/tx/' + txnHash,
  },
  optimism: {
    id: 'optimism',
    chainId: '0xa',
    explorerName: 'Optimism Explorer',
    addressExplorer: (address: string) => 'https://optimistic.etherscan.io/address/' + address,
    nftExplorer: (collectionId: string, tokenId: string) =>
      ['https://optimism.nftscan.com', collectionId, tokenId].join('/'),
    txnExplorer: (txnHash: string) => 'https://optimistic.etherscan.io/tx/' + txnHash,
    txServiceUrl: 'https://safe-transaction-optimism.safe.global/',
  },
  zora: {
    id: 'zora',
    explorerName: 'Zora Explorer',
    addressExplorer: (address: string) => 'https://explorer.zora.energy/address/' + address,
    txnExplorer: (txnHash: string) => 'https://explorer.zora.energy/tx/' + txnHash,
  },
  scroll: {
    id: 'scroll',
    chainId: '0x' + Number(534352).toString(16),
    explorerName: 'Scrollscan',
    nftExplorer: (collectionId: string, tokenId: string) =>
      ['https://scroll.nftscan.com', collectionId, tokenId].join('/'),
    addressExplorer: (address: string) => 'https://scrollscan.com/address/' + address,
    txnExplorer: (txnHash: string) => 'https://scrollscan.com/tx/' + txnHash,
    txServiceUrl: 'https://safe-transaction-scroll.safe.global/',
  },
  story: {
    id: 'story',
    chainId: '0x5ea',
    explorerName: 'StoryScan',
    addressExplorer: (address: string) => 'https://storyscan.xyz/address/' + address,
    txnExplorer: (txnHash: string) => 'https://storyscan.xyz/tx/' + txnHash,
  },
  base: {
    id: 'base',
    chainId: '0x2105',
    explorerName: 'BaseScan',
    addressExplorer: (address: string) => 'https://basescan.org/address/' + address,
    nftExplorer: (collectionId: string, tokenId: string) =>
      ['https://base.nftscan.com', collectionId, tokenId].join('/'),
    txnExplorer: (txnHash: string) => 'https://basescan.org/tx/' + txnHash,
    txServiceUrl: 'https://safe-transaction-base.safe.global/',
  },
  zksyncEra: {
    id: 'zksync-era',
    chainId: '0x' + Number(324).toString(16),
    explorerName: 'zkSync Era Block Explorer',
    addressExplorer: (address: string) => 'https://explorer.zksync.io/address/' + address,
    nftExplorer: (collectionId: string, tokenId: string) =>
      ['https://zksync.nftscan.com', collectionId, tokenId].join('/'),
    txnExplorer: (txnHash: string) => 'https://explorer.zksync.io/tx/' + txnHash,
    txServiceUrl: 'https://safe-transaction-zksync.safe.global/',
  },
  bitcoin: {
    id: 'bitcoin',
    explorerName: 'Blockchair',
    addressExplorer: (address: string) => `https://blockchair.com/bitcoin/address/${address}`,
    txnExplorer: (txnHash: string) => `https://blockchair.com/bitcoin/transaction/${txnHash}`,
  },
  litecoin: {
    id: 'litecoin',
    explorerName: 'Blockchair',
    addressExplorer: (address: string) => `https://blockchair.com/litecoin/address/${address}`,
    txnExplorer: (txnHash: string) => `https://blockchair.com/litecoin/transaction/${txnHash}`,
  },
  bitcoincash: {
    id: 'bitcoin-cash',
    explorerName: 'Blockchair',
    addressExplorer: (address: string) => `https://blockchair.com/bitcoin-cash/address/${address}`,
    txnExplorer: (txnHash: string) => `https://blockchair.com/bitcoin-cash/transaction/${txnHash}`,
  },
  arweave: {
    id: 'arweave',
    explorerName: 'View Block',
    addressExplorer: (address: string) => 'https://viewblock.io/arweave/address/' + address,
    txnExplorer: (txnSignature: string) => 'https://viewblock.io/arweave/tx/' + txnSignature,
  },
  cosmosHub: {
    id: 'cosmoshub-4',
    explorerName: 'Mint Scan',
    addressExplorer: (address: string) => 'https://www.mintscan.io/cosmos/address/' + address,
    txnExplorer: (txnSignature: string) => 'https://www.mintscan.io/cosmos/tx/' + txnSignature,
  },
  osmosis: {
    id: 'osmosis-1',
    explorerName: 'Mint Scan',
    addressExplorer: (address: string) => 'https://www.mintscan.io/osmosis/address/' + address,
    txnExplorer: (txnSignature: string) => 'https://www.mintscan.io/osmosis/tx/' + txnSignature,
  },
  ripple: {
    id: 'ripple',
    explorerName: 'XRP Ledger',
    addressExplorer: (address: string) => 'https://livenet.xrpl.org/accounts/' + address,
    txnExplorer: (txnHash: string) => 'https://livenet.xrpl.org/transactions/' + txnHash,
  },
  starknet: {
    id: 'starknet',
    explorerName: 'Starkscan',
    addressExplorer: (address: string) => 'https://starkscan.co/contract/' + address,
    txnExplorer: (txnHash: string) => 'https://starkscan.co/tx/' + txnHash,
  },
};

export const INTEGRATION_TYPES = {
  NFT: ['nft_collection'],
  VESTING_CONTRACT: ['vesting_contract'],
  API: [
    'anchorage',
    'fireblocks',
    'coinbase',
    'coinbasepro',
    'coinbaseadvanced',
    'coinbasecustody',
    'coinbasecommerce',
    'coinbaseprime',
    'falconx',
    'ftx',
    'ftxus',
    'gemini',
    'kraken',
    'binance',
    'okx',
    'circle_api',
    'bitgo',
    'm2',
    'wintermute',
  ],
  MANUAL_IMPORT: ['anchorage_lending'],
};

export const SESSION_KEYS = {
  currentOrg: 'currentOrg',
};

const STORAGE_KEYS_PREFIX = 'integral__';
export const STORAGE_KEYS = {
  pages: {
    bookkeeping: {
      hiddenColumns: `${STORAGE_KEYS_PREFIX}__bookkeeping__hiddenColumns`,
    },
    bills: {
      hiddenColumns: `${STORAGE_KEYS_PREFIX}__bills__hiddenColumns`,
    },
  },
  lastOrg: 'lastOrg',
  reactQueryOfflineCache: 'REACT_QUERY_OFFLINE_CACHE',
};

// Search params that have global scope (i.e. relevant on any page)
export const GLOBAL_SEARCH_PARAMS = {
  orgId: { key: 'orgId' },
};

type TokenContract = { [token: string]: string };
type ChainTokenContract = { [chain: string]: TokenContract };
export const TOKEN_CONTRACTS: ChainTokenContract = {
  blast: {
    USDB: '0x4300000000000000000000000000000000000003',
    WETH: '0x4300000000000000000000000000000000000004',
    WBTC: '0xF7bc58b8D8f97ADC129cfC4c9f45Ce3C0E1D2692',
    NrETH: '0x9D020B1697035d9d54f115194c9e04a1e4Eb9aF7',
    NrUSDB: '0x96F6b70f8786646E0FF55813621eF4c03823139C',
    ETH: '',
  },
  ethereum: {
    USDC: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48', // Native USDC
    USDT: '0xdac17f958d2ee523a2206206994597c13d831ec7', // Native USDT
    ETH: '',
  },
  polygon: {
    USDC: '0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359', // Native USDC
    WUSDC: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174', // USDC.e
    ETH: '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619', // WETH
  },
  'binance-smart-chain': {
    USDC: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d', // Binance-Peg USDC
    WUSDC: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d', // Binance-Peg USDC
    ETH: '0x2170Ed0880ac9A755fd29B2688956BD959F933F8', // Binance-Peg ETH
  },
  arbitrum: {
    USDC: '0xaf88d065e77c8cC2239327C5EDb3A432268e5831', // Native USDC
    WUSDC: '0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8', // Bridged USDC.e
    ETH: '',
  },
  arbitrumNova: {
    USDC: '0x750ba8b76187092b0d1e87e28daaf484d1b5273b', // Native USDC
    WUSDC: '0x750ba8b76187092b0d1e87e28daaf484d1b5273b', // Native USDC
    ETH: '0x722e8bdd2ce80a4422e880164f2079488e115365', // WETH
  },
  avalanche: {
    USDC: '0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E', // Native USDC
    WUSDC: '0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664', // Bridged USDC.e
    ETH: '0xf20d962a6c8f70c731bd838a3a388d7d48fa6e15', // Bridged old ETH
  },
  optimism: {
    USDC: '0x0b2C639c533813f4Aa9D7837CAf62653d097Ff85', // Native USDC
    WUSDC: '0x7F5c764cBc14f9669B88837ca1490cCa17c31607', // Bridged USDC.e
    ETH: '',
  },
  base: {
    USDC: '0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913', // Native USDC
    WUSDC: '0xd9aAEc86B65D86f6A7B5B1b0c42FFA531710b6CA', // Bridged USDbC
    ETH: '',
  },
  zksyncEra: {
    USDC: '0x3355df6D4c9C3035724Fd0e3914dE96A5a83aaf4',
    WETH: '0x5AEa5775959fBC2557Cc8789bC1bf90A239D9a91',
    ETH: '0x000000000000000000000000000000000000800A',
  },
  starknet: {
    ETH: '0x049d36570d4e46f48e99674bd3fcc84644ddd6b96f7c741b1562b82f9e004dc7',
    USDC: '0x053c91253bc9682c04929ca02ed00b3e423f6710d2ee7e0d5ebb06f3ecf368a8',
    STRK: '0x04718f5a0fc34cc1af16a1cdee98ffb20c31f5cd61d6ab07201858f4287c938d',
    zSTRK: '0x06d8fa671ef84f791b7f601fa79fea8f6ceb70b5fa84189e3159d532162efc21',
  },
};

export enum NonPersistentQueryKeys {
  AttachmentsFiles = 'attachments-files',
}
